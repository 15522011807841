import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="submission-persons"
export default class extends Controller {
    static targets = ["chipDiv", "chipTemplate", "skillInput", 
        "suggestDiv", "formSkillsInput", "submitButton", "formDiv",
        "billRate", "interval", "timezone", "english", "title", "skillLevel", "enableRange", "minRate", "maxRate"
    ]

    suggestOptions = [];

    connect() {
        this.setInitialSkills();
        if (this.hasSkillInputTarget) {
            this.skillInputTarget.addEventListener("input", this.handleSkillInput.bind(this));
            this.skillInputTarget.addEventListener("keydown", this.handleEnterPress.bind(this));
        }
        if (this.hasSubmitButtonTarget) {
            this.submitButtonTarget.addEventListener("click", this.handleSubmit.bind(this));
        }
        this.setOptions();
    }

    setInitialSkills() {
        if (!this.hasChipDivTarget) return;
        const init_skills = this.chipDivTarget.textContent;
        this.chipDivTarget.innerHTML = "";
        init_skills.split(",").forEach(skill => {
            if (skill !== "") {
                this.addSkill(skill.toLowerCase().charAt(0).toUpperCase() + skill.toLowerCase().slice(1));
            }
        });
        this.setVisibility(this.chipDivTarget, true);
    }

    handleEnterPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.skillInputTarget.value = this.skillInputTarget.value.trim() + ',';
            this.handleSkillInput(event);
        }
    }

    handleSkillInput(event) {
        const inputText = event.target.value.trim();
        const currentChoices = [];
        this.chipDivTarget.querySelectorAll("p").forEach((option) => {
            currentChoices.push(option.textContent);
        });

        if (inputText.includes(",")) {
            const skills = inputText.split(',').map(skill => skill.trim());
            skills.forEach(skill => {
                if (skill !== "") {
                    if (!currentChoices.some(choice => choice.toLowerCase() === skill.toLowerCase())) {
                        this.addSkill(skill);
                    }
                }
            });
            event.target.value = "";
            this.setVisibility(this.suggestDivTarget, false);
            return;
        }

        if (inputText.length > 0) {
            const relevantOptions = Array.from(this.suggestOptions).filter((option) => {
                return option.textContent.toLowerCase().includes(inputText.toLowerCase());
            });

            if (relevantOptions.length > 0) {
                this.suggestOptions.forEach((option) => {
                    this.setVisibility(option, false);
                });
                relevantOptions.forEach((option) => {
                    if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                        this.setVisibility(option, true);
                    }
                });
                this.setVisibility(this.suggestDivTarget, true);
            } else {
                this.setVisibility(this.suggestDivTarget, false);
            }
        } else {
            this.setVisibility(this.suggestDivTarget, false)
        }
    }

    setVisibility(element, isVisible) {
        if (isVisible) {
            element.classList.remove("hidden");
        } else if (!isVisible && !element.classList.contains("hidden")) {
            element.classList.add("hidden");
        }
    }

    setOptions() {
        if (!this.hasSuggestDivTarget) return;
        this.suggestOptions = this.suggestDivTarget.querySelectorAll("p");
        this.suggestOptions.forEach((option) => {
            option.addEventListener("click", () => {
                this.addSkill(option.textContent);
            });
        });
    }

    addSkill(chipText) {
        const currentSkills = Array.from(this.chipDivTarget.querySelectorAll("p")).map(skill => skill.textContent.toLowerCase());
        const lowerCaseChipText = chipText.toLowerCase();

        if (!currentSkills.includes(lowerCaseChipText)) {
            const chipTemplateContent = this.chipTemplateTarget.content.cloneNode(true);
            const pTag = chipTemplateContent.querySelector("p");
            pTag.textContent = chipText;

            const chipElement = chipTemplateContent.querySelector("span");
            const deleteIcon = chipElement.querySelector("i");
            deleteIcon.addEventListener("click", (e) => {
                e.stopPropagation();
                chipElement.remove();
            });

            this.chipDivTarget.appendChild(chipTemplateContent);

            this.skillInputTarget.value = "";
            this.setVisibility(this.suggestDivTarget, false);
        }
    }

    handleUncommitted(event) {
        const inputText = this.skillInputTarget.value.trim() + ",";
        const currentChoices = [];
        this.chipDivTarget.querySelectorAll("p").forEach((option) => {
            currentChoices.push(option.textContent);
        });

        if (inputText.endsWith(",")) {
            if (inputText.length > 1 && !currentChoices.some(choice => choice.toLowerCase() === inputText.slice(0, -1).trim().toLowerCase())) {
                this.addSkill(inputText.slice(0, -1).trim());
            }
            this.skillInputTarget.value = "";
            this.setVisibility(this.suggestDivTarget, false);
            return
        } 

        if (inputText.length > 0) {
            const relevantOptions = Array.from(this.suggestOptions).filter((option) => {
                return option.textContent.toLowerCase().includes(inputText.toLowerCase());
            });

            if (relevantOptions.length > 0) {
                this.suggestOptions.forEach((option) => {
                    this.setVisibility(option, false);
                });
                relevantOptions.forEach((option) => {
                    if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                        this.setVisibility(option, true);
                    }
                });
                this.setVisibility(this.suggestDivTarget, true);
            } else {
                this.setVisibility(this.suggestDivTarget, false);
            }
        } else {
            this.setVisibility(this.suggestDivTarget, false)
        }
    }

    clearSkills(event) {
        event.stopPropagation();
        this.chipDivTarget.innerHTML = "";
    }

    handleSubmit(event) {
        if (!this.hasChipDivTarget || !this.hasSkillInputTarget) return;
        event.preventDefault();

        // Handle commiting uncommitted 
        this.handleUncommitted();

        // Enable Skill Input
        this.formSkillsInputTarget.disabled = false;

        const currentChoices = [];
        this.chipDivTarget.querySelectorAll("p").forEach((option) => {
            currentChoices.push(option.textContent);
        });

        const skills = currentChoices.join(",").toLowerCase();

        this.formSkillsInputTarget.value = skills;

        // Trigger the form submit
        if(this.formDivTarget.checkValidity()) {
            this.formDivTarget.submit();
        } else {
            this.formDivTarget.reportValidity();
            // Disable It Again
            setTimeout(() => {
                this.formSkillsInputTarget.disabled = true;
            }, 5000);
        }
    }

    personChanged(event) {
        fetch(`/persons/${event.target.options[event.target.selectedIndex].value}.json`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Get Person Error');
                }
            })
            .then(data => {
                if (this.hasEnableRangeTarget) {
                    const event = new Event('change', { bubbles: true });
                    this.enableRangeTarget.dispatchEvent(event);
                }
                if (this.hasBillRateTarget) { this.billRateTarget.value = data.rate; }
                if (this.hasMinRateTarget) { this.minRateTarget.value = data.rate; }
                if (this.hasMaxRateTarget) { this.minRateTarget.value = data.max_rate; }
                if (this.hasIntervalTarget) { this.intervalTarget.slim.setSelected(data.rate_interval); }
                if (this.hasTimezoneTarget && data.tz) {
                    const timezonesToCheck = ['PST', 'MST', 'CST', 'EST', 'AST', 'HST', 'AKST'];

                    if (timezonesToCheck.some(abbr => data.tz.includes(abbr))) {
                        for (const option of this.timezoneTarget.options) {
                            const currentTimezone = option.value;
                            if (currentTimezone && this.timezoneMatches(data.tz, currentTimezone)) {
                                this.timezoneTarget.slim.setSelected(currentTimezone);
                                break;
                            }
                        }
                    } else {
                        this.timezoneTarget.slim.setSelected(data.tz);
                    }
                }
                if (this.hasEnglishTarget) { this.englishTarget.slim.setSelected(`${data.english}`); }
                if (this.hasChipDivTarget) { this.chipDivTarget.textContent = data.skills; }
                this.setInitialSkills();
                if (this.hasTitleTarget) { this.titleTarget.value = data.title; }
                if (this.hasSkillLevelTarget) { this.skillLevelTarget.slim.setSelected(data.skill_level); }
                const description_trix = document.querySelector("trix-editor");
                if (description_trix) { description_trix.innerHTML = data.description; }
                const documentList = document.querySelector('.documentList');
                if (documentList) { documentList.innerHTML = ""; }
                if (data.person_docs.length !== 0) {
                    const titleParagraph = document.createElement('p');
                    titleParagraph.className = 'text-base font-medium text-[#212b36] mb-1';
                    titleParagraph.textContent = 'Additional Documents';

                    const selectParagraph = document.createElement('p');
                    selectParagraph.className = 'text-xs text-[#212b36] mb-[14px]';
                    const selectSpan = document.createElement('span');
                    selectSpan.className = 'text-xs text-[#212b36]';
                    selectSpan.textContent = 'Select the additional documents you would like to appear on this submission.';
                    selectParagraph.appendChild(selectSpan);

                    const hiddenFieldTag = document.createElement('input');
                    hiddenFieldTag.type = 'hidden';
                    hiddenFieldTag.name = 'submission_person[visible_person_doc_ids][]';
                    hiddenFieldTag.value = '';

                    const div = document.createElement('div');
                    div.appendChild(titleParagraph);
                    div.appendChild(selectParagraph);
                    div.appendChild(hiddenFieldTag);

                    documentList.appendChild(div);

                    data.person_docs.forEach(personDoc => {
                        const checkboxId = `submission_person_visible_person_doc_ids_${personDoc.id}`;
                        const checkbox = document.createElement('input');
                        checkbox.type = 'checkbox';
                        checkbox.name = 'submission_person[visible_person_doc_ids][]';
                        checkbox.value = personDoc.id;
                        checkbox.id = checkboxId;

                        const label = document.createElement('label');
                        label.htmlFor = checkboxId;
                        label.textContent = personDoc.title;
                        label.className = 'ml-2 mb-0 text-gray-500';

                        const docDiv = document.createElement('div');
                        docDiv.className = 'flex items-center mb-2';
                        docDiv.appendChild(checkbox);
                        docDiv.appendChild(label);

                        documentList.appendChild(docDiv);
                    });
                }
                this.populateCustomFields(data.data);

                get(`/submission_persons/person?person_id=${data.id}`, {
                    responseKind: "turbo-stream"
                });

            })
            .catch(error => {
                console.log("Error:", error);
            });
    }

    timezoneMatches(savedTz, currentTz) {

        if (savedTz.includes(' ')) {
            const savedTzAbbreviation = savedTz.split(' ').pop();
            const currentTzAbbreviation = currentTz.split(' ').pop();
            console.log("Saved Abbreviation:", savedTzAbbreviation);
            console.log("Current Abbreviation:", currentTzAbbreviation);

            const match = currentTzAbbreviation.includes(savedTzAbbreviation);
            
            console.log("Abbreviation Match Result:", match);
            
            return match;
        }
    }

    populateCustomFields(customFields) {
        if (!customFields || typeof customFields !== 'object') {
            console.log('Custom fields object is either undefined, null, or not an object.');
            return;
        }

        Object.entries(customFields).forEach(([key, value]) => {
            const targetElement = this.element.querySelector(`[data-submission-persons-target="${key}"]`);

            if (targetElement) {
                console.log(targetElement.type)
                switch (targetElement.type) {
                    case 'text':
                    case 'textarea':
                    case 'number':
                        targetElement.value = value;
                        break;
                    case 'checkbox':
                        targetElement.checked = value;
                        break;
                    case 'radio':
                        const radioButton = Array.from(targetElement.form.elements).find(
                            el => el.type === 'radio' && el.name === targetElement.name && el.value === value
                        );
                        if (radioButton) {
                            radioButton.checked = true;
                        }
                        break;
                    case 'select-one':
                        targetElement.slim.setSelected(value);
                        break;
                    case 'select-multiple':
                        targetElement.slim.setSelected(value);
                        break;
                    default:
                        console.warn(`Unhandled field type: ${targetElement.type}`);
                }
            } else {
                console.warn(`Target element not found for key: ${key}`);
            }
        });
    }
}
